<template>
	<ApplicationsHeader />
	<!--	<div class="mx-auto max-w-2xl my-6 px-6 text-center">-->
	<!--		<a-->
	<!--			href="https://impro-amsterdam.nl/media/images/map_2024.original.jpg"-->
	<!--			target="_blank"-->
	<!--		>-->
	<!--			<img-->
	<!--				src="https://impro-amsterdam.nl/media/images/small_map_2024.original.jpg"-->
	<!--				alt="2024 impro map"-->
	<!--			/>-->
	<!--		</a>-->
	<!--	</div>-->
<!-- TODO move this to a separate component -->
<!--	<div class='bg-white'>-->
<!--		<div class='mx-auto max-w-7xl px-6 lg:px-8'>-->
<!--			<div class='mx-auto mt-16 max-w-2xl sm:mt-8 lg:mt-8 lg:max-w-4xl'>-->
<!--				<dl class='grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16'>-->
<!--					<div v-for='application in store.applications' :key='application.name' class='relative pl-16'>-->
<!--						<dt class='text-base font-semibold leading-7 text-gray-900'>-->
<!--							<a :href='application.href' class="impro-link underline">{{ application.name }}</a>-->
<!--						</dt>-->
<!--						<dd class='mt-2 text-base leading-7 text-gray-600'>{{ application.description }}</dd>-->
<!--					</div>-->
<!--				</dl>-->
<!--			</div>-->
<!--		</div>-->
<!--	</div>-->

	<!--	<Partners />-->
	<div class='bg-white px-6 py-20 lg:px-8'>
		<MailChimpForm />
	</div>
</template>
<script setup lang='ts'>
import { useApplicationsStore } from '~/store/applications'
import ApplicationsEnded from '../components/ApplicationsEnded.vue'
import ApplicationsHeader from '../components/ApplicationsHeader.vue'
import MailChimpForm from '~/components/MailChimpForm.vue'
const store = useApplicationsStore()
</script>
